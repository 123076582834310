<template>
  <div class="row mb-4">
    <select-catalog-type-modal ref="selectCatalogTypeModal" @select="openAdvancedProductDetailsModal" />

    <div class="col-xl-12">
      <div class="row product-card-body nopadding">
        <div class="col-xl-1 col-md-12">
          <div class="row h-100">
            <template v-if="allegro()">
              <div :class="productIds.includes(product().productId) ? 'selected' : ''" class="col-xl-4 col-md-12 py-xl-0 py-2 product-card-select">
                <div class="custom-control custom-checkbox">
                  <input
                      :id="`check-${product().productId}`"
                      :checked="productIds.includes(product().productId)"
                      :class="productIds.includes(product().productId) ? 'custom-control-label-selected' : ''"
                      :value="product().productId"
                      class="custom-control-input"
                      type="checkbox"
                      @change="$parent.$parent.$emit('select-or-unselect-product', product())"
                  />
                  <label :for="`check-${product().productId}`" class="custom-control-label">&nbsp;</label>
                </div>
              </div>
            </template>
            <template v-else>
              <div :class="productIds.includes(product().id) ? 'selected' : ''" class="col-xl-4 col-md-12 py-xl-0 py-2 product-card-select">
                <div class="custom-control custom-checkbox">
                  <input
                      :id="`check-${product().id}`"
                      :checked="productIds.includes(product().id)"
                      :class="productIds.includes(product().id) ? 'custom-control-label-selected' : ''"
                      :value="product().id"
                      class="custom-control-input"
                      type="checkbox"
                      @change="$parent.$parent.$emit('select-or-unselect-product', product())"
                  />
                  <label :for="`check-${product().id}`" class="custom-control-label">&nbsp;</label>
                </div>
              </div>
            </template>

            <div
                class="col-xl-8 col-md-12 py-3 py-xl-0 product-card-image d-flex align-items-center justify-content-center">
              <template v-if="jsonUtil.asArray(product().productImages).length > 0">
                <a class="custom-image" @click="$parent.$parent.$emit('open-images-modal', jsonUtil.asArray(product().productImages))">
                  <v-lazy-image
                      :src="jsonUtil.asArray(product().productImages)[0]"
                      alt="Product Image"
                      class="custom-image"
                      src-placeholder="https://i.imgur.com/OI4ACNk.gif"
                  />
                </a>
              </template>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-md-12 product-card-body">
          <div class="row mb-2" v-if="allegro()">
            <div class="col-12 col-xl-9 order-12 order-xl-1 d-flex justify-content-xl-start justify-content-center">
              <div class="d-flex flex-column d-xl-inline-flex flex-xl-row vw-100">
                <div class="product-info mr-2 mb-2 mb-xl-0" :class="getAllegroStatusBadge()">
                  Status Oferty: <span class="subtitle">{{  getAllegroStatusName() }}</span>
                </div>
                <div class="product-info mr-2 info-orange" v-if="form.allegroAuction ? form.allegroAuction.offer_id : product().offerId">
                  Numer Oferty: <span class="subtitle decoration-underline">{{  form.allegroAuction ? form.allegroAuction.offer_id : product().offerId }}</span>
                </div>
              </div>
            </div>

          <div class="col-12 col-xl-3 order-1 order-xl-12 d-flex justify-content-xl-end justify-content-center pb-2 pb-xl-0" v-if="modifyTimestamp()">
            <div class="d-inline-flex align-items-center">
              <div class="product-edit">
                Produkt zmodyfikowany {{  modifyTimestamp().globalModifyAt > modifyTimestamp().shopModifyAt ? dateUtil.asDateTimeNormal(modifyTimestamp().globalModifyAt) : dateUtil.asDateTimeNormal(modifyTimestamp().shopModifyAt) }} przez:
              </div>

              <img v-if="modifyTimestamp().globalModifyAt" alt="Icon" class="ml-2" src="@/assets/icons/web.svg"/>
              <img v-if="modifyTimestamp().shopModifyAt" alt="Icon" class="ml-2" src="@/assets/icons/home.svg"/>
            </div>
          </div>
          </div>

          <div class="row my-2 my-xl-0">
            <div class="col-xl product-body-title text-xl-left text-center">
              {{ showFullProductName ? product().productName : product().productName.slice(0, 100) }} <span v-if="product().productName.length > 100" @click="showFullProductName = !showFullProductName" class="product-more-info">({{ showFullProductName ? "Ukryj pełną nazwę produktu" : "Pełna nazwa produktu" }})</span>
            </div>
          </div>
          <div class="row">
            <div class="col-xl product-send-target text-center text-xl-left">
              Wysłano do:

              <template class="badge badge-shop-type mr-1 w-sm" v-if="product().sendTarget === 'ALLEGRO'">
                <span class="subtitle">Allegro</span>
              </template>
              <template class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTarget === 'MAGENTO'">
                <span class="subtitle">Aderlo</span>
              </template>
              <template class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTarget === 'WIEJSKI_TARG'">
                <span class="subtitle">Wiejski Targ</span>
              </template>
              <template class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTarget === 'WORDPRESS'">
                <span class="subtitle">WordPress</span>
              </template>
              <template class="badge badge-shop-type mr-1 w-sm" v-else>
                Brak
              </template>

              <!--              <template v-if="product().sendTargetList && product().sendTargetList.length > 0">-->
              <!--                <span v-for="(target, index) in product().sendTargetList" :key="index">-->
              <!--                  <span class="subtitle">{{ correctMarketplaceName(target) }}</span>{{ index === product().sendTargetList.length - 1 ? '.' : ',' }}-->
              <!--                </span>-->
              <!--              </template>-->
              <!--              <template v-else>-->
              <!--                <span class="subtitle">Brak</span>-->
              <!--              </template>-->

              <!--              <span class="badge badge-shop-type mr-1 w-sm" v-if="product().sendTargetList && product().sendTargetList.includes('ALLEGRO')">&ndash;&gt;-->
              <!--                    <img src="@/assets/images/brands/allegro.svg" class="w-75 my-1" alt="Allegro">-->
              <!--                  </span>-->
              <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTargetList && product().sendTargetList.includes('MAGENTO')">-->
              <!--                    <img src="@/assets/images/brands/aderlo.svg" class="w-75 my-1" alt="Aderlo">-->
              <!--                  </span>-->
              <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTargetList && product().sendTargetList.includes('WIEJSKI_TARG')">-->
              <!--                    <img src="@/assets/images/brands/wiejski-targ.svg" class="w-75 my-1" alt="Wiejski Targ">-->
              <!--                  </span>-->
              <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else-if="product().sendTargetList && product().sendTargetList.includes('WORDPRESS')">-->
              <!--                    <img src="@/assets/images/brands/wordpress.svg" class="w-75 my-1" alt="WordPress">-->
              <!--                  </span>-->
              <!--                  <span class="badge badge-shop-type mr-1 w-sm" v-else>-->
              <!--                    Brak-->
              <!--                  </span>-->
              <!--              <span class="subtitle">Allegro</span>, <span class="subtitle">Aderlo</span>,-->
              <!--              <span class="subtitle">Wiejski Targ</span>-->
            </div>
          </div>

          <!--                          <div class="row">-->
          <!--                            <div class="col-xl product-send-target text-center text-xl-left">-->
          <!--                              Produkt dozwolony do wysyłki na: <span class="subtitle">Allegro</span>, <span class="subtitle">Aderlo</span>,-->
          <!--                              <span class="subtitle">Wiejski Targ</span>-->
          <!--                            </div>-->
          <!--                          </div>-->
          <div class="row nopadding py-2">
            <div class="col-xl-3 col-6 product-detail-item">
              Data próby wysłania: <span class="subtitle">{{ product().allegroAuctionCreatedAt ? dateUtil.asDateTimeNormal(product().allegroAuctionCreatedAt) : '-' }}</span>
            </div>
            <div class="col-xl-3 col-6 product-detail-item">
              Data wysłania: <span class="subtitle">{{ product().allegroAuctionActiveFrom ? dateUtil.asDateTimeNormal(product().allegroAuctionActiveFrom) : '-' }}</span>
            </div>
            <div class="col-xl-3 col-6 product-detail-item">
              Data aktualizacji oferty: <span class="subtitle">{{ product().allegroAuctionUpdatedAt ? dateUtil.asDateTimeNormal(product().allegroAuctionUpdatedAt) : '-' }}</span>
            </div>
            <div class="col-xl-3 col-6 product-detail-item">
              Data zakończenia oferty: <span class="subtitle">{{ product().allegroAuctionActiveTo ? dateUtil.asDateTimeNormal(product().allegroAuctionActiveTo) : '-' }}</span>
            </div>
          </div>

          <div class="nopadding overflow-auto d-flex flex-row py-2">
            <div class="col-12 col-xl product-advanced-detail-items">
              <product-detail-card title="SKU:" :description="product().productSku.toString()"/>
              <product-detail-card title="EAN:" :description="eanString()" :badge="ean().length > 1" badge-class="badge-soft-success" :badge-text="eanOthersString()"/>
              <product-detail-card title="Nazwa kategorii:" :description="category() ? category().name : '-'"/>
              <product-detail-card title="Narzut:" :description="productsHelper.profitMarginFormatter(null, null, product())"/>
              <product-detail-card
                  title="Dozwolony do sprzedaży na Allegro:"
                  :badge="true"
                  :badge-class="productHelper.getAllegroSendStatus(product()) ? 'badge-soft-success' : 'badge-soft-danger'"
                  :badge-text="$t(productHelper.getAllegroSendStatus(product()) ? 'message.yes' : 'message.no')"
              />
            </div>

            <div class="col-12 col-xl product-advanced-detail-items">
              <product-detail-card title="Cena hurtowni netto:" :description="productsHelper.parsePrice(product().productPriceWholesaleHigh)"/>
              <product-detail-card title="Stawka podatku:" :description="`${taxRate().toString()}%`"/>
              <product-detail-card title="Czas wysyłki:" :description="deliveryTime()"/>
              <product-detail-card title="Koszt dostawy:" :description="shippingCostsText()"/>
            </div>

            <div v-if="allegro()" class="col-12 col-xl product-advanced-detail-items">
              <product-detail-card title="Błąd:" :description="product().allegroAuctionError ? product().allegroAuctionError : '-'"/>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-12 product-card-actions">
          <!--          <button class="btn btn-outline-yellow" @click="$parent.$parent.$emit('open-details-modal', product())">-->
          <!--            Szczegóły-->
          <!--          </button>-->

          <button class="btn btn-green d-inline-block" @click="$parent.$parent.$emit('send-product', product())">
            Wyślij Produkt
          </button>

          <b-button :href="`https://allegro.pl/oferta/${form.allegroAuction ? form.allegroAuction.offer_id : product().offerId}`" target="_blank" class="btn-outline-orange" :disabled="form.allegroAuction ? !form.allegroAuction.offer_id : !product().offerId">
            Zobacz wystawioną ofertę na Allegro
          </b-button>

          <button class="btn btn-outline-gray" @click="openPriceDetailsModal">
            Szczegóły Ceny
          </button>

          <button class="btn btn-outline-yellow" @click="openSelectCatalogTypeModal"> <!-- @click="this.$refs.advancedProductDetailModal.openModal" -->
            Szczegóły produktu Zmodyfikuj z AI
          </button>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="row nopadding">
        <div class="col-xl-2 card-option">
          <span>EAN:</span>
          <vue-multiselect
              @open="loadProductVariations"
              @select="changeEan"
              :value="form.selectedEan"
              :options="eanList"
              placeholder="Wybierz EAN"
              :show-labels="false">
            <span slot="noOptions">{{ $t('message.list-is-empty')}}</span>
          </vue-multiselect>
        </div>

        <div class="col-xl-2 card-option">
          <span>SKU:</span>
          <vue-multiselect
              @open="loadProductVariations"
              @select="changeSku"
              :value="form.selectedSku"
              :options="skuList"
              placeholder="Wybierz SKU"
              :show-labels="false">
            <span slot="noOptions">{{ $t('message.list-is-empty')}}</span>
          </vue-multiselect>
        </div>
        <div class="col-xl-3 card-option">
          <span>Hurtownia:</span>
          {{ warehouse() ? warehouse().name : '' }}
        </div>
        <div class="col-xl-1 col-6 card-option">
          <span>Ilość sztuk:</span>
          {{ product().productTotalWarehouseStock.toString() }}
        </div>
        <div class="col-xl-2 col-6 card-option">
          <span>Cena sklepu brutto:</span>
          {{ productsHelper.parsePrice(product().productPriceHigh) }}
        </div>
        <div class="col-xl-2 card-option highlight">
          <span>Twój przewidywany zysk:</span>
          {{ productsHelper.profitFormatter(null, null, product(), taxRate()) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductDetailCard from "@/components/product/product-detail-card.vue";
import VLazyImage from "v-lazy-image/v2";
import {jsonUtil} from "@/helpers/json-util";
import {dateUtil} from "@/helpers/date-util";
import {productsHelper} from "@/helpers/products-helper";
import {productHelper} from "@/helpers/product-helper";
import {priceHelper} from "@/helpers/price-helper";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import selectCatalogTypeModal from "@/components/catalog/select-catalog-type-modal.vue";

export default {
  components: {selectCatalogTypeModal, VLazyImage, ProductDetailCard},

  computed: {
    productHelper() {
      return productHelper
    },

    productsHelper() {
      return productsHelper
    },

    dateUtil() {
      return dateUtil
    },

    jsonUtil() {
      return jsonUtil
    }
  },

  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },

    marketplace: {
      type: String,
      default: ""
    },

    productIds: {
      type: Array,
      required: true
    },

    categories: {
      type: Map,
      required: true
    },

    warehouses: {
      type: Map,
      required: true
    },

    taxes: {
      type: Map,
      required: true
    },

    deliveryUnits: {
      type: Map,
      required: true
    },

    shippingCosts: {
      type: Map,
      required: true
    },

    modifyTimestamps: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      showFullProductName: false,

      form: {
        selectedSku: "",
        selectedEan: "",
        shopProduct: null,
        allegroAuction: null,
      },

      allegroAuctions: [],
      productVariations: [],
      skuList: [],
      eanList: []
    }
  },

  methods: {
    product() {
      return this.form.shopProduct ? this.form.shopProduct : this.source
    },
    
    category() {
      if (!this.categories || !this.product().shopCategoryId) {
        return null
      }

      return this.categories.get(this.product().shopCategoryId) || null;
    },

    warehouse() {
      if (!this.warehouses || !this.product().productWarehouseId) {
        return null
      }

      return this.warehouses.get(this.product().productWarehouseId) || null;
    },

    taxRate() {
      if (!this.taxes || this.taxes.size === 0 || !this.product().productTaxId) {
        return 0
      }

      const tax = this.taxes.get(this.product().productTaxId);
      return tax ? tax.rate || 0 : 0;
    },

    deliveryUnit(deliveryUnit) {
      if (!this.deliveryUnits || !deliveryUnit) {
        return null
      }

      return this.deliveryUnits.get(deliveryUnit) || null
    },

    deliveryTime() {
      if (this.product().productDeliveryTime && this.product().productDeliveryUnit) {
        const deliveryUnit = this.deliveryUnit(this.product().productDeliveryUnit)
        if (deliveryUnit) {
          return this.product().productDeliveryTime + " " + deliveryUnit.name
        }
      }

      const warehouse = this.warehouse()
      if (warehouse) {
        const deliveryUnit = this.deliveryUnit(warehouse.deliveryUnit)
        if (deliveryUnit) {
          return warehouse.deliveryTime + " " + deliveryUnit.name
        }
      }

      return this.$t('products.no-delivery-time')
    },

    shippingCostsForProduct() {
      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(this.product().productId) || null;
    },

    shippingCostsText() {
      const shippingCostsForProduct = this.shippingCostsForProduct()
      if (!shippingCostsForProduct) {
        return "-"
      }

      let value = ""
      for (const shippingCost of shippingCostsForProduct) {
        value += `${shippingCost.name}: ${priceHelper.format(shippingCost.cost, this.$store.getters['market/currentCurrency'])} / `
      }

      if (value.endsWith(' ')) {
        value = value.substring(0, value.length - 3)
      }

      return value
    },

    ean() {
      if (!this.product().productEan) {
        return []
      }

      return jsonUtil.asArray(this.product().productEan).filter(element => element)
    },

    eanString() {
      const ean = this.ean()
      if (ean && ean.length > 0) {
        if (ean[0]) {
          return ean[0].toString()
        }
      }

      return "-"
    },

    eanOthersString() {
      const ean = this.ean()
      if (ean && ean.length > 1) {
        return `+${ean.length - 1} ${this.$t('message.others')}`
      }
    },

    allegro() {
      return this.marketplace === "ALLEGRO"
    },

    column() {
      return this.allegro() ? 'col-sm-3' : 'col-sm-4'
    },

    modifyTimestamp() {
      return this.modifyTimestamps.find(element => element.productId === this.product().productId)
    },

    openPriceDetailsModal() {
      const product = this.product()
      this.$parent.$parent.$emit('open-price-details-modal', { name: product.productName, images: jsonUtil.asArray(product.productImages), priceHigh: product.productPriceHigh, priceWholesaleHigh: product.productPriceWholesaleHigh, profitMargin: product.productProfitMargin, warehouseProfitMargin: product.warehouseProfitMargin, tax: { rate: this.taxRate() }  })
    },

    openSelectCatalogTypeModal() {
      if (!this.$store.getters['allegro/getCurrentAllegroAccountId']) {
        Swal.fire("", "Aby móc edytować szczegóły produktu należy wybrać konto Allegro!", "error");
        return
      }

      this.$refs.selectCatalogTypeModal.openModal()
    },

    openAdvancedProductDetailsModal(catalogType) {
      this.$parent.$parent.$emit('open-advanced-product-details-modal', catalogType, this.product())
    },

    getAllegroStatusBadge() {
      switch (this.form.allegroAuction ? this.form.allegroAuction.status : this.product().allegroAuctionStatus) {
        case "PREPARED_TO_SEND":
          return "info-warning"
        case "SEND_ERROR":
          return "info-danger"
        case "ACTIVE":
          return "info-green"
        case "ENDED_BY_SYSTEM":
          return "info-danger"
        case "ENDED_BY_USER":
          return "info-danger"
      }
    },

    getAllegroStatusName() {
      switch (this.form.allegroAuction ? this.form.allegroAuction.status : this.product().allegroAuctionStatus) {
        case "PREPARED_TO_SEND":
          return "W trakcie wysyłania"
        case "SEND_ERROR":
          return "Błąd"
        case "ACTIVE":
          return "Aktywna"
        case "ENDED_BY_SYSTEM":
          return "Zakończona przez System"
        case "ENDED_BY_USER":
            return "Zakończona przez Użytkownika"
      }
    },

    async loadProductVariations() {
      if (this.productVariations.length > 0) {
        return
      }

      try {
        const {data} = await axios.get(`/allegro/auction/auction-data`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            shopId: this.product().shopId,
            productId: this.product().productId
          }
        });

        this.allegroAuctions = JSON.parse(data.allegroAuctions)
        this.productVariations = JSON.parse(data.productVariations)
        this.skuList = this.productVariations.map(productVariation => 'W' + productVariation.sku)
        this.eanList = this.productVariations
            .filter(productVariation => productVariation.ean)
            .map(productVariation => productVariation.ean[0])
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async changeEan(ean) {
      this.form.selectedEan = ean
      const selectedSku = this.productVariations.find(element => element.ean.includes(ean))
      if (selectedSku) {
        this.form.selectedSku = 'W' + selectedSku.sku
      }

      this.changeProduct()
    },

    async changeSku(sku) {
      this.form.selectedSku = sku

      const searchSku = Number(this.form.selectedSku.replace("W", ""))
      const selectedEan = this.productVariations.find(element => element.sku === searchSku)
      if (selectedEan) {
        this.form.selectedEan = selectedEan.ean[0]
      }

      this.changeProduct()
    },

    changeProduct() {
      const searchSku = Number(this.form.selectedSku.replace("W", ""))
      const productVariation = this.productVariations.find(element => element.sku === searchSku)

      if (productVariation) {
        this.form.shopProduct.sku = productVariation.sku
        this.form.shopProduct.ean = productVariation.ean
        this.form.shopProduct.productPriceWholesaleHigh = productVariation.price
        const allegroAuction = this.allegroAuctions.find(element => element.product_variation_id === productVariation.id)
        if (allegroAuction) {
          this.form.allegroAuction = allegroAuction
        }
      }
    }
  },

  mounted() {
    this.form.shopProduct = this.source
  }

}
</script>