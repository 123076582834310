<template>
  <div class="hover-test my-1">
    <template v-if="sortByField">
      <span @click="changeSortable" class="clickable-element">
        <template v-if="sortableByField !== sortByField">
          <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 13L7 9H0L3.5 13Z" fill="#AFB2BB" fill-opacity="0.25"/>
            <path d="M3.5 -3.0598e-07L-5.91144e-07 4L7 4L3.5 -3.0598e-07Z" fill="#AFB2BB" fill-opacity="0.25"/>
          </svg>
        </template>
        <template v-else>
          <template v-if="sortableBy">
            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.5 13L7 9H0L3.5 13Z" fill="#4892F1"/>
              <path d="M3.5 -3.0598e-07L-5.91144e-07 4L7 4L3.5 -3.0598e-07Z" fill="#4892F1" fill-opacity="0.05"/>
            </svg>
          </template>
          <template v-else>
            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.5 13L7 9H0L3.5 13Z" fill="#4892F1" fill-opacity="0.25"/>
              <path d="M3.5 -3.0598e-07L-5.91144e-07 4L7 4L3.5 -3.0598e-07Z" fill="#4892F1"/>
            </svg>
          </template>
        </template>
      </span>
    </template>

    <span class="font-weight-bold product-title-color font-size-12 mr-1" :class="sortableByField === sortByField ? 'product-selected' : ''">
      {{ title }}
    </span>
    <span class="font-size-12 product-detail-color" v-if="description">
      {{ description }}
    </span>

    <slot />

    <span v-if="badge" class="badge font-size-11 mr-1" :class="badgeClass">
      {{ badgeText }}
    </span>

    <slot name="actions"></slot>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    },

    sortableBy: {
      type: Boolean,
      default: false
    },

    sortByField: {
      type: String,
      default: null
    },

    sortableByField: {
      type: String,
      default: ""
    },

    description: {
      type: String,
      default: ""
    },

    badge: {
      type: Boolean,
      default: false
    },

    badgeClass: {
      type: String,
      default: ""
    },

    badgeText: {
      type: String,
      default: ""
    }
  },

  methods: {
    changeSortable() {
      this.$root.$emit("products-change-sortable", this.sortByField)
    }
  }

}
</script>