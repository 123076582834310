<script>
import {priceHelper} from "@/helpers/price-helper";

export default {
  name: "mini-product",
  computed: {
    priceHelper() {
      return priceHelper
    }
  },

  props: {
    productVariation: {
      type: Object,
      required: true
    },

    isSelected: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showFullProductName: false
    }
  }

}
</script>

<template>
    <div @click="$emit('select', productVariation)" class="w-100 mini-product-details" :class="{ selected: isSelected }">
      <div class="mini-product-header">
        <img :src="productVariation.images[0]" alt="Product Icon"/>
        <div class="mini-product-title">
          {{ showFullProductName ? productVariation.name : productVariation.name.slice(0, 100) }} <span v-if="productVariation.name.length > 100" @click="showFullProductName = !showFullProductName" class="product-more-info">({{ showFullProductName ? "Ukryj pełną nazwę produktu" : "Pełna nazwa produktu" }})</span>
        </div>
      </div>
      <div class="mini-product-body">
        <div class="mini-product-alert" v-if="productVariation.attributeError">
          {{ productVariation.attributeError ? "Błąd atrybutów" : "" }}
        </div>
        <div class="row mini-product-data nopadding">
          <div class="col-6 col-sm mini-product-info">
            Cena: <span>{{ priceHelper.format(productVariation.priceMarginTaxIncluded, $store.getters['market/currentCurrency']) }}</span>
          </div>
          <div class="col-6 mini-product-info">
            Ilość sztuk: <span>{{  productVariation.availableIn }}</span>
          </div>
          <div class="col-6 mini-product-info">
            SKU: <span>{{  productVariation.sku }}</span>
          </div>
          <div class="col-6 mini-product-info">
            Kod producenta: <span>{{  productVariation.manufacturerCode ? productVariation.manufacturerCode : "Brak" }}</span>
          </div>
          <div class="col-6 mini-product-info">
            EAN: <span>{{  productVariation.ean }}</span>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
.mini-product-alert {
  background-color: var(--product-mini-alert-bg);
  color: var(--product-mini-alert);

  height: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: bold;
}

.mini-product-details.selected {
  background-color: var(--product-mini-selected-bg);

  border-color: var(--product-mini-selected-border);

  border-radius: 5px;

  box-shadow: 0 0 0 3px var(--product-mini-selected-shadow);

  .mini-product-header {
    background-color: var(--product-mini-selected-header-bg);

    border-radius: 5px 5px 0 0;

    border-color: var(--product-mini-selected-header-border);
  }

  .mini-product-body {
    border-color: var(--product-mini-selected-body-border);
  }
}

.mini-product-title {
  padding-left: 15px;

  font-size: 14px;
  font-weight: bold;

  color: var(--product-mini);
}

.mini-product-title span {
  font-size: 14px;
  font-weight: normal;

  color: var(--product-mini-title);
}

@media (min-width: 1280px) {
  .mini-product-info:nth-child(odd) {
    padding-right: 2px;
  }

  .mini-product-info:nth-child(even) {
    padding-left: 2px;
  }
}

.mini-product-title span:hover {
  cursor: pointer;
}

.mini-product-header {
  background-color: var(--product-mini-header-bg);

  border: 1px solid var(--product-mini-header-border);

  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.mini-product-header img {
  width: 50px;
  height: 50px;
}

.mini-product-details {
  cursor: pointer;
  margin: 0 15px 15px 15px;

  background-color: var(--product-mini-details-bg);

  border-radius: 5px;

  border: 1px solid var(--product-mini-details-border);
}

.mini-product-details:first-child {
  margin: 15px;
}

.mini-product-data {
  padding: 15px 5px !important;
}

.mini-product-info {
  padding-bottom: 10px;
}

.mini-product-info:last-child {
  padding-bottom: 0;
}

.mini-product-info {
  font-size: 12px;
  font-weight: bold;

  color: var(--product-mini-info);
}

.mini-product-info span {
  font-size: 12px;
  font-weight: normal;

  color: var(--product-mini-info-title);
}
</style>