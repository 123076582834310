<script>
import Layout from "../../../layouts/main.vue";
import ShopProductsView from "@/components/product/shop-products-view.vue";

export default {
  components: {
    ShopProductsView,
    Layout
  }
}
</script>

<template>
  <Layout>
    <shop-products-view :shop-id="$store.getters['parameters/getAderloShopId']" />
  </Layout>
</template>