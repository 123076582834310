<script>
export default {
  name: "operation-price-box",

  props: {
    customClass: {
      type: String,
      default: ""
    },

    isResult: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div class="col-xl text-center card-details-box" :class="isResult ? 'result' : '' + customClass">
    <div>
      <slot/>
    </div>
  </div>
</template>

<style scoped>
.card-details-box {
  font-size: 14px;
  font-weight: normal;

  padding: 10px 5px;

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: var(--product-details);

  background-color: var(--product-details-bg);
  border-radius: 5px;
}

.card-details-box.result {
  color: var(--product-details-result);
  background-color: var(--product-details-result-bg);
}

.card-details-box.result span {
  color: var(--product-details-result-title);
}

.card-details-box span {
  padding-left: 5px;
  font-size: 14px;
  font-weight: bold;

  color: var(--product-details-title);
}

.card-details-box.highlight {
  color: var(--product-details-highlight) !important;
  background-color: var(--product-details-highlight-bg) !important;
}

.card-details-box.highlight span {
  color: var(--product-details-highlight-title) !important;
}
</style>